import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import ecLogo from '../../_assets/svg/eu-logo_white.svg';
import './footer.scss';
import IconSocial from '../../_shared/iconsocial/IconSocial';

/**
 * EC Component: Footer
 * html: https://ec.europa.eu/component-library/playground/ec/?path=/story/components-site-wide-site-footer--harmonised
 * js: No js
 */

const Footer: FC = () => {
  const { t } = useTranslation();

  const relatedItems = [
    {
      label: t('FOOTER.HORIZON_MAGAZINE.LABEL'),
      link: t('FOOTER.HORIZON_MAGAZINE.LINK'),
    },
    {
      label: t('FOOTER.EXHIBITION.LABEL'),
      link: t('FOOTER.EXHIBITION.LINK'),
    },
  ];

  const linkItemsLeft = [
    {
      label: t('FOOTER.CONTACT.LABEL'),
      link: t('FOOTER.CONTACT.LINK'),
    },
    {
      label: t('FOOTER.SOCIAL.LABEL'),
      link: t('FOOTER.SOCIAL.LINK'),
    },
    {
      label: t('FOOTER.RESOURCES.LABEL'),
      link: t('FOOTER.RESOURCES.LINK'),
    },
    {
      label: t('FOOTER.IT_VULNERABILITY.LABEL'),
      link: t('FOOTER.IT_VULNERABILITY.LINK'),
    },
  ];

  const linkItemsRight = [
    {
      label: t('FOOTER.LANGUAGE_POLICY.LABEL'),
      link: t('FOOTER.LANGUAGE_POLICY.LINK'),
    },
    {
      label: t('FOOTER.COOKIES.LABEL'),
      link: t('FOOTER.COOKIES.LINK'),
    },
    {
      label: t('FOOTER.PRIVACY_POLICY.LABEL'),
      link: t('FOOTER.PRIVACY_POLICY.LINK'),
    },
    {
      label: t('FOOTER.LEGAL.LABEL'),
      link: t('FOOTER.LEGAL.LINK'),
    },
  ];

  return (
    <footer className="ecl-site-footer" id="footer">
      <div className="ecl-container ecl-site-footer__container">
        <div className="ecl-site-footer__row">
          <div className="ecl-site-footer__column">
            <div className="ecl-site-footer__section ecl-site-footer__section--site-info">
              <div className="ecl-site-footer__title">
                <a className="ecl-link ecl-link--standalone ecl-site-footer__title-link" href={t('FOOTER.TITLE.LINK')}>
                  {t('FOOTER.TITLE.LABEL')}
                </a>
              </div>
              <div className="ecl-site-footer__description">
                {t('FOOTER.MANAGED_BY_DESCRIPTION')}
                <br />
                {t('FOOTER.MANAGED_BY')}
              </div>
              <ul className="ecl-site-footer__list">
                <li className="ecl-site-footer__list-item">
                  <a
                    className="ecl-link ecl-link--standalone ecl-link--inverted ecl-site-footer__link"
                    href={t('FOOTER.ACCESSIBILITY.LINK')}
                  >
                    {t('FOOTER.ACCESSIBILITY.LABEL')}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="ecl-site-footer__column">
            <div className="ecl-site-footer__section">
              <div className="ecl-site-footer__title">{t('FOOTER.CONTACT_US.LABEL')}</div>
              <ul className="ecl-site-footer__list">
                <li className="ecl-site-footer__list-item">
                  <a
                    className="ecl-link ecl-link--standalone ecl-link--inverted ecl-site-footer__link"
                    href={t('FOOTER.CONTACT_US.LINK')}
                  >
                    {t('FOOTER.CONTACT_US.DESCRIPTION')}
                  </a>
                </li>
              </ul>
            </div>
            <div className="ecl-site-footer__section">
              <div className="ecl-site-footer__title">{t('FOOTER.FOLLOW_US')}</div>
              <ul className="ecl-site-footer__list ecl-site-footer__list--inline">
                <li className="ecl-site-footer__list-item">
                  <a
                    className="ecl-link ecl-link--standalone ecl-link--inverted ecl-link--icon ecl-site-footer__link"
                    href="https://www.facebook.com/EUScienceInnov"
                  >
                    <IconSocial name="facebook-negative" size="xs" />
                    <span className="ecl-link__label">{t('SHARED.FACEBOOK')}</span>
                  </a>
                </li>
                <li className="ecl-site-footer__list-item">
                  <a
                    className="ecl-link ecl-link--standalone ecl-link--inverted ecl-link--icon ecl-site-footer__link"
                    href="https://twitter.com/EUScienceInnov"
                  >
                    <IconSocial name="twitter-negative" size="xs" />
                    <span className="ecl-link__label">{t('SHARED.X')}</span>
                  </a>
                </li>
                <li className="ecl-site-footer__list-item">
                  <a
                    className="ecl-link ecl-link--standalone ecl-link--inverted ecl-link--icon ecl-site-footer__link"
                    href="https://www.linkedin.com/showcase/european-commission-joint-research-centre/?originalSubdomain=be"
                  >
                    <IconSocial name="linkedin-negative" size="xs" />
                    <span className="ecl-link__label">{t('SHARED.LINKEDIN')}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="ecl-site-footer__column">
            <div className="ecl-site-footer__section">
              <div className="ecl-site-footer__title">{t('FOOTER.RELATED_SITES')}</div>
              <ul className="ecl-site-footer__list">
                {relatedItems.map(({ link, label }) => (
                  <li className="ecl-site-footer__list-item" key={label}>
                    <a className="ecl-link ecl-link--standalone ecl-link--inverted ecl-site-footer__link" href={link}>
                      {label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="ecl-site-footer__row">
          <div className="ecl-site-footer__column">
            <div className="ecl-site-footer__section">
              <a
                aria-label={t('SITE_HEADER.EC_HOME')}
                className="ecl-link ecl-link--standalone ecl-site-footer__logo-link"
                href="https://commission.europa.eu/index_en"
              >
                <picture className="ecl-picture ecl-site-footer__picture" title={t('SITE_HEADER.EC_HOME')}>
                  <source media="(min-width: 996px)" srcSet={ecLogo} />
                  <img alt={t('SITE_HEADER.EC_LOGO_ALT')} className="ecl-site-footer__logo-image" src={ecLogo} />
                </picture>
              </a>
            </div>
          </div>
          <div className="ecl-site-footer__column">
            <div className="ecl-site-footer__section ecl-site-footer__section--split-list">
              <ul className="ecl-site-footer__list">
                {linkItemsLeft.map(({ label, link }) => (
                  <li className="ecl-site-footer__list-item" key={label}>
                    <a className="ecl-link ecl-link--standalone ecl-link--inverted ecl-site-footer__link" href={link}>
                      {label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="ecl-site-footer__column">
            <div className="ecl-site-footer__section ecl-site-footer__section--split-list">
              <ul className="ecl-site-footer__list">
                {linkItemsRight.map(({ label, link }) => (
                  <li className="ecl-site-footer__list-item" key={label}>
                    <a className="ecl-link ecl-link--standalone ecl-link--inverted ecl-site-footer__link" href={link}>
                      {label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
